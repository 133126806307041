import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import * as React from 'react'

import { cn } from '#app/utils/misc.tsx'

export const className =
	'z-50 overflow-hidden rounded-md border bg-background px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2'

const TooltipProvider = ({
	delayDuration = 100,
	skipDelayDuration = 1000,
	...props
}: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Provider>) => (
	<TooltipPrimitive.Provider
		delayDuration={delayDuration}
		skipDelayDuration={skipDelayDuration}
		{...props}
	/>
)
TooltipProvider.displayName = TooltipPrimitive.Provider.displayName

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef<
	React.ElementRef<typeof TooltipPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ sideOffset = 4, ...props }, ref) => (
	<TooltipPrimitive.Portal>
		<TooltipPrimitive.Content
			ref={ref}
			sideOffset={sideOffset}
			className={cn(className, props.className)}
			{...props}
		/>
	</TooltipPrimitive.Portal>
))

TooltipContent.displayName = TooltipPrimitive.Content.displayName

const SimpleTooltip = React.forwardRef<
	React.ElementRef<typeof TooltipPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root> & {
		className?: string
		message: React.ReactNode
		contentProps?: React.ComponentPropsWithoutRef<
			typeof TooltipPrimitive.Content
		>
		triggerProps?: React.ComponentPropsWithoutRef<
			typeof TooltipPrimitive.Trigger
		>
	}
>(
	(
		{ className, children, message, contentProps, triggerProps, ...props },
		ref,
	) => (
		<Tooltip {...props}>
			<TooltipContent {...contentProps}>
				<p className="text-xs font-normal">{message}</p>
			</TooltipContent>
			<TooltipTrigger
				{...triggerProps}
				className={className}
				onClick={e => e.preventDefault()}
			>
				{children}
			</TooltipTrigger>
		</Tooltip>
	),
)
SimpleTooltip.displayName = 'SimpleTooltip'

export {
	Tooltip,
	TooltipTrigger,
	TooltipContent,
	TooltipProvider,
	SimpleTooltip,
}
